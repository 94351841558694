<template lang="pug">
v-row.justify-center.align-center.pt-10
  v-col.col-12.text-center
    h1
      | Proiecte
  v-col.col-12.col-sm-10.col-md-8
    v-card
      v-card-title.mb-2
        v-row.justify-center.align-center.no-gutters
          v-col.col-12
            h4
              | Proiect ERASMUS +
          v-col.col-12
            h5(style="word-break: break-word")
              | “Critical theory in adult education practice: Empowerment for critical actions” (MIND)
      v-card-subtitle
        | Nr. 2023-1-LT01-KA220-ADU-000153426
      v-card-text
        p
          | Proiectul MIND este finanțat de Comisia Europeană prin programul Erasmus+ și are ca scop dezvoltarea competențelor de educație critică a adulților.
          | Proiectul se desfășoară pe o perioadă de 24 de luni, începând cu 1 Decembrie 2023 și se adresează adulților cu vârsta de peste 18 ani, care doresc să își dezvolte competențele de educație critică.
        h3
          | Obiective
        p
          | Proiectul își propune să promoveze abordarea gândirii critice în educația non-formală pentru adulți prin consolidarea capacităților profesionale ale formatorilor și prin împuternicirea diverselor grupuri de cursanți să aplice și să cultive gândirea critică (CT) în diferite sfere ale vieții personale, profesionale și sociale.
          | Acest lucru va fi realizat prin consolidarea cunoștințelor științifice și a bunelor practici, prin intermediul organizațiilor partenere active în domeniile educației adulților, educației generale și învățământului superior.
        h3
          | Activități
        p
          | Proiectul va efectua analiza literaturii științifice, investigarea și analiza documentelor și programelor de educație pentru adulți, proiectarea unui model de dezvoltare a gândirii critice, dezvoltarea programului și a unui set de instrumente pentru predare, precum și recomandări pentru factorii de decizie în domeniul educației adulților.
          | Activitățile de învățare între colegi și învățare reciprocă vor avea loc pe tot parcursul proiectului la diferite niveluri și cu diferitele grupuri țintă - echipa de proiect, formatori, cursanți adulți.
        h3
          | Rezultate
        p
          | Activitățile proiectului vor oferi răspunsuri bazate pe dovezi la întrebări precum: Ce este gândirea critică în educația non-formală pentru adulți? Care este valoarea acesteia pentru individ, organizație, comunitate și societate? Cum poate fi dezvoltată și aplicată în mod specific în diferite situații și contexte?
          | Răspunsurile vor fi integrate în următoarele livrabile: Compendiu de literatură științifică, Raport de analiză a documentelor/programelor de educație pentru adulți, Program de Gândire Critică și un set de instrumente pentru predare, Recomandări pentru politicile de educație pentru adulți.
        h3
          | Parteneri
        ul 
          li
            | Lithuanian Association of Adult Education, Lithuania (coordinator)
          li
            | Šiuolaikinių didaktikų centras, Lithuania
          li
            | Educational development Center, Latvia
          li
            | Latvian Adult Education Association, Latvia
          li
            | Ustanova za obrazovanje odraslih Dante, Croatia
          li
            | Pucko otvoreno uciliste Libar, Croatia
          li
            | ASE București, România
          li
            | Asociația Egomundi, România
        v-row.justify-center.align-center
          v-col.col-12.col-sm-6
            v-img(src="@/assets/cofonded-by-eu.jpeg", height="200px", contain)
          v-col.col-12.col-sm-6
            v-img(src="@/assets/mind-text.png", height="200px", contain)
  v-col.col-12.col-sm-10.col-md-8
    v-card
      v-card-title.mb-2
        v-row.justify-center.align-center.no-gutters
          v-col.col-12
            h4
              | Pro digitalizare în educație – PRODIGY
      v-card-text
        p
          | Proiectul Pro digitalizare în educație – PRODIGY este finanțat de Uniunea Europeană prin Planul Național de Redresare și Reziliență, în cadrul apelului PNRR: Pedagogie digitală pentru cadrele didactice din învățământul preuniversitar, Componenta C15 – Educație. Reforma 5. Adoptarea cadrului legislativ pentru digitalizarea educației. Investiția 8. Program de formare la locul de muncă pentru personalul didactic.
        p
          | Proiectul este implementat de Universitatea Internationala Danubius, in calitate de lider, de Asociatia Egomundi, de Fundația EOS – Educating for an Open Society, de CASA CORPULUI DIDACTIC BRĂILA si de CASA CORPULUI DIDACTIC ARGEȘ, pentru o perioada de 12 luni.
        p
          | Obiectivul general este creșterea numărului de cadre didactice din preuniversitar care vor dobândi competențe digitale și de pedagogie digitală, pentru a integra eficient tehnologiile în practicile de predare – învățare - evaluare, a pregăti elevii pentru era digitală și pentru a crește calitatea actului educațional la nivel național.
          | Dezvoltarea competențelor de pedagogie digitală pentru cadrele didactice, inclusiv cele legate de colectarea și utilizarea eficientă a instrumentelor și a resurselor disponibile în activitățile de predare, evaluare, comunicarea cu părinții, crearea și schimbul de conținut și resurse digitale.
        h3
          | Obiectivele propuse ale proiectului
        ol
          li
            | Derularea de programe formare profesională de dezvoltare a competențelor digitale și de pedagogie digitală pentru 5000 de cadre didactice;
          li
            | Publicarea de minim 2500 de materiale educaționale deschise pe platforma educred.ro pentru schimbul de practici;
          li
            | Activități de mentorat pentru participanți (GT), pentru aplicarea în activitatea didactică a competențelor obținute în urma participării la programul de formare, pentru o perioadă de 3 luni;
          li
            | Dotarea școlilor din care provin membri GT cu echipamente hardware si software educațional;
        br
        h3
          | Consorțiul PRODIGY
        ol
          li
            | Universitatea Internațională Danubius, România (lider)
          li
            | Asociația Egomundi, România
          li
            | Fundația EOS – Educating for an Open Society, România
          li
            | Casa Corpului Didactic Brăila, România
          li
            | Casa Corpului Didactic Argeș, România
        br
        p
          | Mai multe detalii pe
          a.ml-1(href="https://projectprodigy.ro/index.php", target="_blank")
            | site-ul proiectului
        v-row.justify-center.align-center.no-gutters
          v-col.col-12.col-sm-4
            a(
              href="https://european-union.europa.eu/index_ro",
              target="_blank"
            )
              v-img(src="@/assets/eu-logo.jpg", height="200px", contain)
          v-col.col-12.col-sm-4
            a(href="https://www.gov.ro/", target="_blank")
              v-img(src="@/assets/gov-logo.jpg", height="100px", contain)
          v-col.col-12.col-sm-4
            a(href="https://proiecte.pnrr.gov.ro/#/home", target="_blank")
              v-img(src="@/assets/pnrr-logo.jpg", height="100px", contain)
          v-col.col-12
            v-img(src="@/assets/prodigy-logo.jpeg", height="200px", contain)
</template>

<script>
export default {
  name: "ProjectsView",
};
</script>
